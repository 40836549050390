// 保护展示与环境整治工程记录
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'GCMC',
                label: '工程名称',
                minWidth: 135,
            }, {
                prop: 'GCFL_BM',
                label: '工程分类'
            }, {
                prop: 'bhgcjzqk',
                label: '进展情况'
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '工程分类',
            clearable: true,
            placeholder: "请选择工程分类",
            list: [{
                ID: '0',
                NAME: '环境整治'
            },
            {
                ID: '1',
                NAME: '拆迁'
            },
            {
                ID: '2',
                NAME: '保护展示'
            },
            {
                ID: '3',
                NAME: '清理拆除'
            },
            {
                ID: '4',
                NAME: '维修加固'
            },
            {
                ID: '5',
                NAME: '结构检测和加固'
            }
            ],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'GCFL',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;